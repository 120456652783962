import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { axiosVendorServiceInstance } from "../services/api";
import Loader from "../layouts/Loader";

const VendorContext = createContext();

export const useVendor = () => useContext(VendorContext);

export const VendorProvider = ({ children }) => {
  const [vendorData, setVendorData] = useState({
    availableTime: [],
    isShopActive: false,
    isDeliveryActive: false,
    isTakeawayActive: false,
    isDineInActive: false,
    areasAndDeliveryFee: {},
    bankDetail: [],
    vendorPOSCharges: [],
    dispatchedModes: [],
    bufferTime: "",
  });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const getVendorData = async () => {
      try {
        const userId = JSON.parse(localStorage.getItem("userId"));
        const vendorDataResponse = await axiosVendorServiceInstance.get(
          `/vendor-service/home-vendor-services?userId=${userId}`
        );

        const data = vendorDataResponse.data.data;
        setVendorData({
          availableTime: data["vendor-available-times"] || [],
          isShopActive: data["vendor-status"] === "ACTIVE",
          areasAndDeliveryFee: data["vendor-area-delivery-fee"] || {},
          bankDetail: data["vendor-bank-details"] || [],
          vendorPOSCharges: data["vendor-pos-charges"] || [],
          dispatchedModes: data["vendor-dispatch-modes"] || [],
          bufferTime: data["vendor-buffer-time"],
          isDeliveryActive: data["vendor-dispatch-modes"]?.includes("DELIVERY"),
          isTakeawayActive:
            data["vendor-dispatch-modes"]?.includes("TAKE_AWAY"),
          isDineInActive: data["vendor-dispatch-modes"]?.includes("DINE_IN"),
        });
      } catch (err) {
        if (!err.response) {
          navigate("/feasto/maintenance");
        } else if (err.response.status !== 401) {
          navigate("/feasto/error", {
            state: {
              errNo: err.response.status,
              loginUrl: "/auth/loginVendor/email",
              tryAgainUrl: "/vendor/vendorService",
              goHomeUrl: "/vendor/allOrders",
            },
          });
        }
      } finally {
        setLoading(false); // Ensure loading is set to false whether data is fetched or an error occurred
      }
    };

    getVendorData();
  }, [navigate]);

  if (loading) {
    return <div><Loader/></div>; // Simple loading indicator
  }

  return (
    <VendorContext.Provider value={vendorData}>
      {children}
    </VendorContext.Provider>
  );
};
