import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import payhere from "../../../../assets/payhere.png";
import paypal from "../../../../assets/paypal.png";
import stripe from "../../../../assets/stripe.png";
import { digitalPayment, dpProviders } from "../../../../DomainConstants.js";
import { VendorWebViewInfoDialogBox } from "../../../../Styles.js";
import { FullscreenExit } from "@mui/icons-material";
import { axiosVendorServiceInstance } from "../../../../services/api.js";
import { toast } from "react-toastify";

export default function PaymentMethodSelection(props) {
  const isLargeScreen = useMediaQuery("(min-width:1069px)");
  const isMediumScreen = useMediaQuery(
    "(min-width:700px) and (max-width:1069px)"
  );
  const isBoth = isLargeScreen || isMediumScreen;
  const navigate = useNavigate();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const vendor = JSON.parse(localStorage.getItem("user"));
  const incorporatedMethods = props.incorporatedMethods;
  const [loadingProvider, setLoadingProvider] = useState(null);

  const handleProviderSelection = (providerName) => {
    const payHereCredentials = vendor.payHereCredentials;
    if (digitalPayment.PAYHERE === providerName && payHereCredentials) {
      navigate("/vendor/configurePaymentMethod", {
        state: {
          title: providerName,
          payHereCredentials: payHereCredentials,
          isEdit: false,
        },
      });
    } else if (digitalPayment.STRIPE === providerName) {
      handleCreateStripeConnectedAccount();
    } else {
      navigate("/vendor/configurePaymentMethod", {
        state: {
          title: providerName,
          isEdit: false,
        },
      });
    }
  };

  const handleCreateStripeConnectedAccount = async () => {
    setLoadingProvider(digitalPayment.STRIPE);
    localStorage.setItem("closeDrawerAfterRedirect", JSON.stringify(true));
    const domainName = window.location.hostname;
    try {
      const response = await axiosVendorServiceInstance.post(
        `/vendor-service/create-stripe-account?userId=${vendor.userId}&domainName=${domainName}`
      );

      window.location.href = response.data.data;
      setTimeout(() => {
        setLoadingProvider(null);
        closeDrawer();
      }, 5000);
    } catch (err) {
      setLoadingProvider(null);
      if (err.response === undefined) {
      } else {
        const errmessage = err.response.data.error;
        toast.error(errmessage);
      }
    }
  };

  const handleAddNewClick = (event) => {
    event.stopPropagation();
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
    props.setDrawerOpen(false);
  };

  const style = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    alignSelf: "stretch",
    width: "100%",
  };

  const Wrapper = !isBoth ? SwipeableDrawer : VendorWebViewInfoDialogBox;

  const wrapperProps = !isBoth
    ? {
        PaperProps: {
          sx: { backgroundColor: "transparent", boxShadow: "none" },
        },
        anchor: "bottom",
        open: drawerOpen || props.drawerOpen,
        onClose: closeDrawer,
        onOpen: () => setDrawerOpen(true),
        sx: { width: "100%", maxWidth: "100%", background: "transparent" },
        "aria-setsize": FullscreenExit,
      }
    : {
        open: drawerOpen || props.drawerOpen,
      };
  return (
    <>
      {incorporatedMethods && incorporatedMethods.length > 0 && (
        <Grid
          onClick={(event) => handleAddNewClick(event)}
          sx={{
            display: "flex",
            marginTop: "10px",
            background: "var(--white, #FFF)",
            justifyContent: "flex-end",
            alignItems: "center",
            height: "23.4px",
          }}
        >
          <IconButton
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "6px",
              padding: "0px",
            }}
          >
            <AddRoundedIcon
              style={{
                color: "#E52D3C",
                width: "24px",
                height: "24px",
              }}
            />
          </IconButton>
          <Typography
            sx={{
              color: "var(--gray-500, #E52D3C)",
              fontFamily: "Poppins, sans-serif",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "16px",
              cursor: "pointer",
            }}
          >
            Add new
          </Typography>
        </Grid>
      )}

      <Wrapper {...wrapperProps}>
        <Container
          maxWidth="sm"
          sx={{
            display: "inline-flex",
            flexDirection: "column",
            alignItems: "center",
            height: "auto",
            width: "100%",
            borderRadius: "32px 32px 0px 0px",
            justifyContent: "space-between",
            background: "var(--white, #FFF)",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              padding: "18px 0px",
              alignItems: "center",
              borderBottom: "1px solid var(--gray-200, #E5E7EB)",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Grid
              item
              xs={11}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Typography
                sx={{
                  color: "#000",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontWeight: 500,
                  fontStyle: "normal",
                  lineHeight: "23.4px",
                }}
              >
                Select the platform
              </Typography>
            </Grid>
            <Grid
              item
              xs={1}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                sx={{
                  backgroundColor: "#FFFFFF",
                  "&:hover": { backgroundColor: "#FFFFFF" },
                }}
                onClick={(e) => {
                  closeDrawer(e);
                }}
              >
                <CloseIcon sx={{ color: "#9CA3AF", padding: "0px" }} />
              </Button>
            </Grid>
          </Grid>
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <List sx={style}>
              {dpProviders.map((provider, index) => {
                const isMethodNotIncluded =
                  !incorporatedMethods ||
                  !incorporatedMethods.some(
                    (pm) => pm.name === provider.name.toUpperCase()
                  );
                return (
                  isMethodNotIncluded && (
                    <React.Fragment key={provider.name}>
                      <ListItem
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          cursor: provider.isAvailable
                            ? "pointer"
                            : "not-allowed",
                        }}
                        onClick={
                          provider.isAvailable
                            ? () => {
                                handleProviderSelection(provider.name);
                              }
                            : null
                        }
                      >
                        <Grid container>
                          <Grid item xs={12}>
                            <Grid
                              container
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Grid item xs={2}>
                                {provider.name === digitalPayment.PAYHERE && (
                                  <img src={payhere} alt="PayHere" />
                                )}
                                {provider.name === digitalPayment.STRIPE && (
                                  <img src={stripe} alt="Stripe" />
                                )}
                                {provider.name === digitalPayment.PAYPAL && (
                                  <img src={paypal} alt="Paypal" />
                                )}
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "left",
                                  paddingLeft: "5px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "#111827",
                                    fontFamily: "Poppins, sans-serif",
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {provider.name}{" "}
                                  {loadingProvider === provider.name && (
                                    <>
                                      {" "}
                                      <span
                                        style={{
                                          marginLeft: "8px",
                                        }}
                                      >
                                        Connecting
                                      </span>
                                      <CircularProgress
                                        sx={{
                                          color: "#9CA3AF",
                                          marginLeft: "10px",
                                        }}
                                        size={24}
                                      />
                                    </>
                                  )}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                }}
                              >
                                {!provider.isAvailable && (
                                  <Typography
                                    sx={{
                                      padding: " 1px 12px",
                                      borderRadius: "12px",
                                      background: "var(--gray-100, #F3F4F6)",
                                      color: "var(--gray-800, #1F2937)",
                                      fontFamily: "Poppins",
                                      fontSize: "12px",
                                      fontStyle: "normal",
                                      fontWeight: 500,
                                      lineHeight: "normal",
                                    }}
                                  >
                                    COMING SOON
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                            {index < dpProviders.length - 1 && <Divider />}
                          </Grid>
                        </Grid>
                      </ListItem>
                    </React.Fragment>
                  )
                );
              })}
            </List>
          </Grid>
        </Container>
      </Wrapper>
    </>
  );
}
