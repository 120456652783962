// ProfileBasedLoader.jsx
import React, { useEffect } from "react";
import { axiosCustomerServiceInstance } from "../services/api";
import styled from "styled-components";
import { useLoader } from "./LoaderContext";
import Loader from "./Loader";

const ProfileBasedLoader = () => {
  const { profileImage, setProfileImage, isLoading, setIsLoading } =
    useLoader();

  useEffect(() => {
    const pathParts = window.location.pathname.split("/");
    const lastPathSegment = pathParts[pathParts.length - 1];
    const vendorUrlFormatted = encodeURIComponent(
      lastPathSegment.replace(/ /g, "-").toLowerCase()
    );
    // const vendorUrl = pathParts[pathParts.length - 1];

    const fetchProfileImage = async () => {
      if (profileImage) {
        setIsLoading(false);
        return;
      }

      try {
        const vendorAndItemsResponse = await axiosCustomerServiceInstance.get(
          `/customer-service/vendor-image?vendorUrl=${vendorUrlFormatted}&domainName=${window.location.hostname}`
        );

        setProfileImage(vendorAndItemsResponse.data.data.body);
      } catch (error) {
        console.error("Error fetching profile image:", error);
        setProfileImage(null);
      } finally {
        setIsLoading(false);
      }
    };

    if (pathParts.length === 2) {
      fetchProfileImage();
    }
  }, [profileImage, setProfileImage, setIsLoading]);

  if (!profileImage) {
    return <Loader />;
  }

  return (
    <StyledWrapper>
      <div className="loader-container">
        <div className="logo-container">
          {profileImage && (
            <img
              src={`data:image/png;base64,${profileImage}`}
              alt="Profile Loader"
              className="profile-loader-image"
              style={{
                width: "100px",
                height: "100px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          )}
        </div>
        <section className="dots-container" style={{ marginTop: "24px" }}>
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </section>
      </div>
    </StyledWrapper>
  );
};
const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full screen height */
  width: 100vw; /* Full screen width */
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff; /* Optional: add a background color */

  .loader-container {
    text-align: center;
  }

  .logo-container {
    transform: scale(0.8); /* Scale down the container */
    display: inline-block; /* Ensure the container wraps tightly around the SVG */
  }

  .dots-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .dot {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    border-radius: 10px;
    background-color: #9ca3af;
    animation: pulse 1.5s infinite ease-in-out;
  }

  .dot:last-child {
    margin-right: 0;
  }

  .dot:nth-child(1) {
    animation-delay: -0.3s;
  }

  .dot:nth-child(2) {
    animation-delay: -0.1s;
  }

  .dot:nth-child(3) {
    animation-delay: 0.1s;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.8);
      background-color: #9ca3af;
      box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
    }

    50% {
      transform: scale(1.2);
      background-color: #d1d5db;
      box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);
    }

    100% {
      transform: scale(0.8);
      background-color: #e5e7eb;
      box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
    }
  }
`;
export default ProfileBasedLoader;
